import React, { lazy, Suspense } from 'react';
import { Loading } from 'react-admin';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';

const App = lazy(() => import('./App'));

const title = 'BO VAT Liability';

fetch('./config.json')
  .then((response) => response.json())
  .then((config) => {
    window.env = config;

    // Monitor your app with Sentry
    Sentry.init({
      dsn: window.env.SENTRY_DSN,
      environment: window.env.ENV,
    });

    // Monitor your app with Google Analytics
    if (window.env.ENV === 'prd') {
      ReactGA.initialize(window.env.TRACKING_ID);
    }
  })
  .catch(() => {
    window.env = {};
  })
  .finally(() => {
    ReactDOM.render(
      <React.StrictMode>
        <Suspense fallback={<Loading name={title} />}>
          <App title={title} />
        </Suspense>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });
